import React from "react";
import { Outlet } from "react-router-dom";
import { useFeatureFlagContext } from "../base/providers/featureFlag";
import { UnauthorizedView } from "../base/body/unauthorizedView";

function PartsRoutes(): JSX.Element {
    const { canUseDsp } = useFeatureFlagContext();
    const [component, setComponent] = React.useState(<></>);

    React.useEffect(() => {
        setComponent(() => {
            switch (canUseDsp) {
                case true:
                    return (<Outlet />);
                case false:
                    return (<UnauthorizedView requiredPermission="Parts Scanning" />);
            }
        })
    }, [canUseDsp]);

    return component;
}

export { PartsRoutes }