import { useAdminFeatureFlagContext } from "../../../context";
import { DeleteButton } from "../../../../base/crud";

type AdminDeleteButtonProps = {
    id: number
}

export function AdminDeleteButton({ id }: AdminDeleteButtonProps): JSX.Element {
    const { deleteRecord } = useAdminFeatureFlagContext();

    return (
        <DeleteButton
            deleteId={id}
            deleteAction={deleteRecord}
            />
    );
}