import React from "react";
import { useAdminContext } from "../base/providers";
import { AdminFeatureFlagProvider } from "./context";
import { Outlet } from "react-router-dom";

function AdminRoutes(): JSX.Element {
    const { isAdmin } = useAdminContext();
    const [component, setComponent] = React.useState<JSX.Element>(<></>);
    
    React.useEffect(() => {
        setComponent(() => {
            switch (isAdmin) {
                case true:
                    return (
                        <AdminFeatureFlagProvider>
                            <Outlet />
                        </AdminFeatureFlagProvider>
                    );
                case false:
                    return <></>;
            }
        })
    }, [isAdmin]);

    return (component);
}

export { AdminRoutes }