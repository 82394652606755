import { GridColDef } from "@mui/x-data-grid";
import { AdminDeleteButton, AdminEditButton } from "../../buttons";

export const columns: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        width: 90
    },
    {
        field: "dealerId",
        flex: 1,
        headerName: "Dealer ID",
    },
    {
        field: "locationId",
        flex: 1,
        headerName: "Location ID",
    },
    {
        field: "canUseDsv",
        flex: 1,
        headerName: "Vehicle Scanning",
        valueFormatter: (value: boolean) => value ? "Yes" : "No"
    },
    {
        field: "canUseDsp",
        flex: 1,
        headerName: "Parts Scanning",
        valueFormatter: (value: boolean) => value ? "Yes" : "No"
    },
    {
        field: "canUseDspAudits",
        flex: 1,
        headerName: "New Audits",
        valueFormatter: (value: boolean) => value ? "Yes" : "No"
    },
    {
        field: "canFindDspAuditSupersessions",
        flex: 2,
        headerName: "Supersession Variance Flag",
        valueFormatter: (value: boolean) => value ? "Yes" : "No"
    },
    {
        field: "edit",
        flex: 0.5,
        headerName: "",
        renderCell: (params) => (
            <AdminEditButton
                dealerId={params.row.dealerId}
                locationId={params.row.locationId}
                />
        )
    },
    {
        field: "delete",
        flex: 0.5,
        headerName: "",
        renderCell: (params) => (<AdminDeleteButton id={params.row.id} />)
    }
];