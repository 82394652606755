import { Stack, Typography } from "@mui/material";
import { AdminDataGrid } from "./dataGrid";
import { AdminCreateButton } from "./buttons";
import { useAdminFeatureFlagContext } from "../context";
import React from "react";

function AdminFeatureFlagView(): JSX.Element {
    const { refreshRecords } = useAdminFeatureFlagContext();

    React.useEffect(() => {
        refreshRecords();
    }, [refreshRecords]);

    return (
        <Stack
            direction="column"
            spacing={2}
            >
            <Typography variant="h5">Feature Flags</Typography>
            <AdminCreateButton />
            <AdminDataGrid />
        </Stack>
    );
}

export { AdminFeatureFlagView }