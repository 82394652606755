import { Stack, Typography } from "@mui/material";

type UnauthorizedViewProps = {
    requiredPermission: string
}

export function UnauthorizedView({ requiredPermission }: UnauthorizedViewProps): JSX.Element {
    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center"
            }}
            >
            <Typography
                variant="h4"
                gutterBottom={true}
                >
                Unauthorized
            </Typography>
            <Typography
                variant="body1"
                >
                You must have the {requiredPermission} permission to view this page. Please contact support.
            </Typography>
        </Stack>
    );
}