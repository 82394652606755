import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function AdminCancelButton(): JSX.Element {
    const navigate = useNavigate();

    const onClickCancelButton = (): void => {
        navigate("/admin");
    }
    return (
        <Button
            color="secondary"
            onClick={onClickCancelButton}
            variant="outlined"
            >
            Cancel
        </Button>
    )
}