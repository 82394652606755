import React from "react";
import { GenericDataGrid } from "../../../base/dataGrid";
import { useAdminFeatureFlagContext } from "../../context";
import { FeatureFlagResult } from "types/admin/featureFlags";
import { MappedRow } from "./types";
import { columns } from "./columns";

function AdminDataGrid(): JSX.Element {
    const { records } = useAdminFeatureFlagContext();
    const [mappedRows, setMappedRows] = React.useState<MappedRow[]>([]);

    React.useEffect(() => {
        setMappedRows(records.map((value: FeatureFlagResult) => {
            return {
                id: value.id,
                dealerId: value.dealerId,
                locationId: value.locationId,
                canUseDsp: value.canUseDsp,
                canUseDspAudits: value.canUseDspAudits,
                canFindDspAuditSupersessions: value.canFindDspAuditSupersessions,
                canUseDsv: value.canUseDsv
            };
        }));
    }, [records]);

    return (
        <GenericDataGrid
            columns={columns}
            rows={mappedRows}
            />
    );
}

export { AdminDataGrid }