import { FeatureFlagResult, GetFeatureFlagResultResponse } from "types/admin/featureFlags";

type FeatureFlagStateType = {
    records: FeatureFlagResult[]
}

type FeatureFlagReducerActionType =
    | { type: "SET_RECORDS", payload: GetFeatureFlagResultResponse }

const featureFlagInitialState: FeatureFlagStateType = {
    records: []
};

function FeatureFlagReducer(state: FeatureFlagStateType, action: FeatureFlagReducerActionType): FeatureFlagStateType {
    switch (action.type) {
        case "SET_RECORDS":
            return {
                ...state,
                records: action.payload.records
            };
    }
}

export {
    featureFlagInitialState,
    FeatureFlagReducer
}