import { GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
    {
        field: "partNumber",
        flex: 1,
        headerName: "Part Number"
    },
    {
        field: "matchedBins",
        flex: 2,
        headerName: "Matched Bins",
        valueFormatter: (value: string[]) => value.join(", ")
    },
    {
        field: "mismatchedBin",
        flex: 1,
        headerName: "Mismatched Bin"
    },
    {
        field: "binOne",
        flex: 1,
        headerName: "Bin Loc",
        valueFormatter: (value: string) => value.length > 0 ? value : "-"
    },
    {
        field: "binTwo",
        flex: 1,
        headerName: "Bin 2",
        valueFormatter: (value: string) => value.length > 0 ? value : "-"
    },
    {
        field: "binThree",
        flex: 1,
        headerName: "Dirty Core Bin",
        valueFormatter: (value: string) => value.length > 0 ? value : "-"
    }
]