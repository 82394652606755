import { FeatureFlagResult, GetFeatureFlagResultResponse } from "types/admin/featureFlags";

type AdminStateType = {
    records: FeatureFlagResult[]
}

type AdminReducerActionType =
    | { type: "SET_RECORDS", payload: GetFeatureFlagResultResponse }

const adminInitialState: AdminStateType = {
    records: []
};

function AdminReducer(state: AdminStateType, action: AdminReducerActionType): AdminStateType {
    switch (action.type) {
        case "SET_RECORDS":
            return {
                ...state,
                records: action.payload.records
            };
    }
}

export {
    adminInitialState,
    AdminReducer
}