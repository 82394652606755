import React from "react";
import { useApiService } from "../../base/providers";
import { adminInitialState, AdminReducer } from "./reducer";
import { FeatureFlagResult, GetFeatureFlagResultResponse } from "types/admin/featureFlags";
import { CreateFeatureFlagFormValues, UpdateFeatureFlagFormValues } from "./types";

type AdminFeatureFlagContextType = {
    records: FeatureFlagResult[]
    createRecord: (request: CreateFeatureFlagFormValues) => Promise<void>
    updateRecord: (request: UpdateFeatureFlagFormValues) => Promise<void>
    deleteRecord: (id: number) => Promise<void>
    refreshRecords: () => void
}

const AdminFeatureFlagContext = React.createContext<AdminFeatureFlagContextType | undefined>(undefined);

type AdminFeatureFlagProviderProps = {
    children: React.ReactNode
}

function AdminFeatureFlagProvider(props: AdminFeatureFlagProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(AdminReducer, adminInitialState);

    const getRecordsCallback = React.useCallback(() => {
        apiService.featureFlag.getAll()
            .then((response: GetFeatureFlagResultResponse) => dispatch({ type: "SET_RECORDS", payload: response }))
            .catch(err => {
                console.error("Unable to get feature flag data", err);
            });
    }, [apiService]);

    const createRecordCallback = React.useCallback((request: CreateFeatureFlagFormValues): Promise<void> => {
        return new Promise((resolve, reject) => {
            const { dealerId, locationId, canUseDsp, canUseDspAudits, canFindDspAuditSupersessions, canUseDsv } = request;
            apiService.featureFlag.create(dealerId, locationId, canUseDsp, canUseDspAudits, canFindDspAuditSupersessions, canUseDsv)
                .then(() => resolve())
                .catch(err => reject(err));
        });
        
    }, [apiService]);

    const updateRecordCallback = React.useCallback((request: UpdateFeatureFlagFormValues): Promise<void> => {
        return new Promise((resolve, reject) => {
            const { id, canUseDsp, canUseDspAudits, canFindDspAuditSupersessions, canUseDsv } = request;
            apiService.featureFlag.update(id, canUseDsp, canUseDspAudits, canFindDspAuditSupersessions, canUseDsv)
                .then(() => resolve())
                .catch();
        });
        
    }, [apiService]);

    const deleteRecordCallback = React.useCallback((id: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            apiService.featureFlag.delete(id)
                .then(() => getRecordsCallback())
                .then(() => resolve())
                .catch(err => reject(err));
        });        
    }, [apiService, getRecordsCallback]);

    
    React.useEffect(() => {
        getRecordsCallback();
    }, [getRecordsCallback]);

    return (
        <AdminFeatureFlagContext.Provider
            value={{
                records: state.records,
                createRecord: createRecordCallback,
                updateRecord: updateRecordCallback,
                deleteRecord: deleteRecordCallback,
                refreshRecords: getRecordsCallback
            }}
            >
            {props.children}
        </AdminFeatureFlagContext.Provider>
    );
}

function useAdminFeatureFlagContext(): AdminFeatureFlagContextType {
    const context = React.useContext(AdminFeatureFlagContext);

    if (!context) {
        throw new Error("useAdminFeatureFlagContext must be used within a AdminFeatureFlagProvider");
    }

    return context;
}

export { AdminFeatureFlagProvider, useAdminFeatureFlagContext }