import { Button, Chip } from "@mui/material";
import { useDspAuditVarianceContext } from "../../context";

export function MismatchTabButton() {
    const { binMismatches, setAuditVarianceType } = useDspAuditVarianceContext();
    const onClickMismatchTabButton = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        setAuditVarianceType("mismatch");
    }

    return (
        <Button onClick={onClickMismatchTabButton}>
            Bin Mismatches&nbsp;
            <Chip
                color="primary"
                label={binMismatches.length}
                />
        </Button>
    );
}