import { AxiosInstance } from "axios";
import { ApiService } from "./apiService";
import { CreateFeatureFlagResponse, GetFeatureFlagResultResponse } from "types/admin/featureFlags";

class FeatureFlagApiService extends ApiService {
    super(api: AxiosInstance) { }

    getAll(): Promise<GetFeatureFlagResultResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get("/api/featureflag/getall");
    
                const getFeatureFlagResultResponse = response.data as GetFeatureFlagResultResponse;
                resolve(getFeatureFlagResultResponse);
            } catch (err) {
                reject(err);
            }
        });
    }

    get(dealerId: string, locationId: number): Promise<GetFeatureFlagResultResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.get(`/api/featureflag?dealerId=${encodeURIComponent(dealerId)}&locationId=${encodeURIComponent(locationId)}`);

                const getFeatureFlagResultResponse = response.data as GetFeatureFlagResultResponse;
                resolve(getFeatureFlagResultResponse);
            } catch (err) {
                reject(err);
            }
        })
    }

    create(dealerId: string, locationId: number, canUseDsp: boolean, canUseDspAudits: boolean, canFindDspAuditSupersessions: boolean, canUseDsv: boolean): Promise<CreateFeatureFlagResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.post("/api/featureflag", {
                    dealerId: dealerId,
                    locationId: locationId,
                    canUseDsp: canUseDsp,
                    canUseDspAudits: canUseDspAudits,
                    canFindDspAuditSupersessions: canFindDspAuditSupersessions,
                    canUseDsv: canUseDsv,
                });

                const createFeatureFlagResponse = response.data as CreateFeatureFlagResponse;
                resolve(createFeatureFlagResponse);
            } catch (err) {
                reject(err);
            }
        });
    }

    update(id: number, canUseDsp?: boolean, canUseDspAudits?: boolean, canFindDspAuditSupersessions?: boolean, canUseDsv?: boolean): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.patch("/api/featureflag", {
                    id: id,
                    canUseDsp: canUseDsp,
                    canUseDspAudits: canUseDspAudits,
                    canFindDspAuditSupersessions: canFindDspAuditSupersessions,
                    canUseDsv: canUseDsv,
                });
                
                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                } 

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    delete(id: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.delete(`/api/featureflag?id=${id}`);

                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }
}

export { FeatureFlagApiService }