import { UpdateFeatureFlagFormValues } from "../../../../context/types";

type AdminCreateFormReducerActionType =
    | { type: "SET_RECORD", payload: UpdateFeatureFlagFormValues }
    | { type: "CHANGE_CAN_USE_DSP", payload: boolean }
    | { type: "CHANGE_CAN_USE_DSP_AUDITS", payload: boolean }
    | { type: "CHANGE_CAN_FIND_DSP_AUDIT_SUPERSESSIONS", payload: boolean }
    | { type: "CHANGE_CAN_USE_DSV", payload: boolean }

const adminEditFormInitialState: UpdateFeatureFlagFormValues = {
    id: 0,
    canUseDsp: false,
    canUseDspAudits: false,
    canFindDspAuditSupersessions: false,
    canUseDsv: false
};

function AdminEditFormReducer(state: UpdateFeatureFlagFormValues, action: AdminCreateFormReducerActionType): UpdateFeatureFlagFormValues {
    switch (action.type) {
        case "SET_RECORD":
            return {
                id: action.payload.id,
                canUseDsp: action.payload.canUseDsp,
                canUseDspAudits: action.payload.canUseDspAudits,
                canFindDspAuditSupersessions: action.payload.canFindDspAuditSupersessions,
                canUseDsv: action.payload.canUseDsv,
            }
        case "CHANGE_CAN_USE_DSP":
            return {
                ...state,
                canUseDsp: action.payload,
                canUseDspAudits: action.payload && state.canUseDspAudits,
                canFindDspAuditSupersessions: action.payload && state.canFindDspAuditSupersessions,
            };
        case "CHANGE_CAN_USE_DSP_AUDITS":
            return {
                ...state,
                canUseDspAudits: state.canUseDsp && action.payload,
            };
        case "CHANGE_CAN_FIND_DSP_AUDIT_SUPERSESSIONS":
            return {
                ...state,
                canFindDspAuditSupersessions: state.canUseDsp && action.payload,
            };
        case "CHANGE_CAN_USE_DSV":
            return {
                ...state,
                canUseDsv: action.payload,
            };
    }
}

export {
    adminEditFormInitialState,
    AdminEditFormReducer
}