import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

type AdminEditButtonProps = {
    dealerId: string
    locationId: number
}

export function AdminEditButton({ dealerId, locationId }: AdminEditButtonProps): JSX.Element {
    const navigate = useNavigate();

    const onClickEdit = (): void => {
        navigate(`/admin/update/${dealerId}/${locationId}`);
    }

    return (
        <IconButton
            color="primary"
            onClick={onClickEdit}
            >
            <EditOutlinedIcon />
        </IconButton>
    );
}