import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function AdminCreateButton(): JSX.Element {
    const navigate = useNavigate();

    const onClickCreate = (): void => {
        navigate("/admin/create");
    }
    
    return (
        <Grid item md={12} lg={3}>
            <Button
                color="primary"
                onClick={onClickCreate}
                variant="contained"
                >
                Add Customer
            </Button>
        </Grid>
        
    );
}