import { CreateFeatureFlagFormValues } from "../../../../context/types";

type AdminCreateFormReducerActionType =
    | { type: "CHANGE_DEALER_ID", payload: string }
    | { type: "CHANGE_LOCATION_ID", payload: number }
    | { type: "CHANGE_CAN_USE_DSP", payload: boolean }
    | { type: "CHANGE_CAN_USE_DSP_AUDITS", payload: boolean }
    | { type: "CHANGE_CAN_FIND_DSP_AUDIT_SUPERSESSIONS", payload: boolean }
    | { type: "CHANGE_CAN_USE_DSV", payload: boolean }

const adminCreateFormInitialState: CreateFeatureFlagFormValues = {
    dealerId: "",
    locationId: 0,
    canUseDsp: false,
    canUseDspAudits: false,
    canFindDspAuditSupersessions: false,
    canUseDsv: false
};

function AdminCreateFormReducer(state: CreateFeatureFlagFormValues, action: AdminCreateFormReducerActionType): CreateFeatureFlagFormValues {
    switch (action.type) {
        case "CHANGE_DEALER_ID":
            return {
                ...state,
                dealerId: action.payload,
            };
        case "CHANGE_LOCATION_ID":
            return {
                ...state,
                locationId: action.payload,
            };
        case "CHANGE_CAN_USE_DSP":
            return {
                ...state,
                canUseDsp: action.payload,
                canUseDspAudits: action.payload && state.canUseDspAudits,
                canFindDspAuditSupersessions: action.payload && state.canFindDspAuditSupersessions,
            };
        case "CHANGE_CAN_USE_DSP_AUDITS":
            return {
                ...state,
                canUseDspAudits: state.canUseDsp && action.payload,
            };
        case "CHANGE_CAN_FIND_DSP_AUDIT_SUPERSESSIONS":
            return {
                ...state,
                canFindDspAuditSupersessions: state.canUseDsp && action.payload,
            };
        case "CHANGE_CAN_USE_DSV":
            return {
                ...state,
                canUseDsv: action.payload,
            };
    }
}

export {
    adminCreateFormInitialState,
    AdminCreateFormReducer
}