import { useAuth } from "../providers";
import { Outlet } from "react-router-dom";
import { UnauthenticatedView } from "./unauthenticatedView";
import React from "react";

function ContentContainer(): JSX.Element {
    const { isLoggedIn } = useAuth();
    const [component, setComponent] = React.useState(<UnauthenticatedView />);

    React.useEffect(() => {
        setComponent(() => {
            switch (isLoggedIn) {
                case true:
                    return (<Outlet />);
                case false:
                    return (<UnauthenticatedView />);
            }
        });
    }, [isLoggedIn]);

    return component;
}

export { ContentContainer };